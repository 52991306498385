import { Provider } from 'react-redux';

import { setupStore } from '@app/store';

const store = setupStore();

type AppStoreProviderType = {
  children: React.ReactNode;
};

export const AppStoreProvider = ({ children }: AppStoreProviderType) => {
  return <Provider store={store}>{children}</Provider>;
};
