import { combineReducers, configureStore } from '@reduxjs/toolkit';
import authSlice from '@src/features/Auth/model/authSlice';

const rootReducer = combineReducers({
  authSlice: authSlice
});

export const setupStore = () => {
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
      serializableCheck: false
    }),
  });
};

export type RootState = ReturnType<typeof rootReducer>;
