import { createTheme, MantineProvider } from '@mantine/core';
import { ReactNode } from 'react';

const theme = createTheme({});

export const AppMantineProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  return (
    <MantineProvider theme={theme} defaultColorScheme="dark">
      {children}
    </MantineProvider>
  );
};
