import { useEffect } from 'react';
import  { Map } from 'maplibre-gl';

import 'maplibre-gl/dist/maplibre-gl.css';
import { Box } from '@mantine/core';


export const AHclaimingMap = () => {
    useEffect(() => {
        const map = new Map({
          container: 'map',
          style: 'https://map.rzd.expert/styles/basic-preview/style.json',
          center: [37.6156, 55.7522 ],
          zoom: 5,
        });
    
        return () => {
          map.remove();
        };
      }, []);
    
      return (
        <Box
          id="map"
          style={{ width: '100%', height: '100vh' }}
        />
      );
}