import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type AuthState = {
  isLoading: boolean;
  isError: string;
  isSuccess: boolean;
};

const initialState: AuthState = {
  isLoading: false,
  isError: '',
  isSuccess: false,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setError: (state, action: PayloadAction<string>) => {
      state.isError = action.payload;
    },
    setSuccess: (state, action: PayloadAction<boolean>) => {
      state.isSuccess = action.payload;
    },
    resetAuthState: (state) => {
      state.isLoading = false;
      state.isError = '';
      state.isSuccess = false;
    },
  },
});

export const { setLoading, setError, setSuccess, resetAuthState } = authSlice.actions;

export default authSlice.reducer;