import { Text, Anchor } from '@mantine/core';

type FooterFormPropsType = {
  onClick(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void;
};

export const FooterForm = ({ onClick }: FooterFormPropsType) => {
  return (
    <Text ta="center" mt="md">
      Don&apos;t have an account?{' '}
      <Anchor<'a'> href="#" fw={700} onClick={onClick}>
        Registration
      </Anchor>
    </Text>
  );
};
