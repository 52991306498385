import axiosInstance from "@src/shared/axiosConfig";

interface ILoginCredentials{
    email: string;
    password: string;
}

export const loginApi = async  (credentials:  ILoginCredentials) => {
    const { data } =  await  axiosInstance.post('/api/user/login', {
        email:  credentials.email,
        password: credentials.password
    })

    return  data
}