import { loginApi } from "@src/features/Auth/api/loginApi";
import { setError, setLoading, setSuccess } from "@src/features/Auth/model/authSlice";
import { ErrorType } from "@src/shared/@types";
import { AppDispatch } from "@src/shared/hooks/useRedux/AppDispatch";

interface ILoginCredentials{
    email: string;
    password: string;
}

export const login = (credentials: ILoginCredentials) => async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));
    try {
     const data = await loginApi(credentials);
     localStorage.setItem('token', data.token)
     
      dispatch(setSuccess(true));
    } catch (error) {
      dispatch(setError((error as ErrorType).message));
    } finally {
      dispatch(setLoading(false));
    }
};

