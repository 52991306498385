import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAppDispatch } from '@src/shared/hooks/useRedux/useAppDispatch';

import { Paper, TextInput, PasswordInput, Button, Title } from '@mantine/core';

import { FooterForm } from './components/FooterForm';
import { login } from '@src/features/Auth/model/authActions';

import classes from './AuthenticationImage.module.css';

type InputChangeType = 'email' | 'password';

export const LoginPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [userState, setUserState] = useState({
    email: '',
    password: '',
  });

  const handleLogIn = async () => {
    await dispatch(login(userState));
    localStorage.setItem('token', 'testLogin')
    navigate('/');
  };

  const handleNavigate = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ) => {
    event.preventDefault();
    navigate('/registration');
  };

  const handleInputChange =
    (key: InputChangeType) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setUserState((prev) => ({ ...prev, [key]: event.target.value }));
    };

  return (
    <div className={classes.wrapper}>
      <Paper className={classes.form} radius={0} p={30}>
        <Title order={2} className={classes.title} ta="center" mt="md" mb={50}>
          Welcome back to chat with your AHclaiming!
        </Title>

        <TextInput
          label="Email address"
          placeholder="hello@gmail.com"
          size="md"
          onChange={handleInputChange('email')}
        />
        <PasswordInput
          label="Password"
          placeholder="Your password"
          mt="md"
          size="md"
          onChange={handleInputChange('password')}
        />
        <Button fullWidth mt="xl" size="md" onClick={handleLogIn}>
          Login
        </Button>

        <FooterForm onClick={handleNavigate} />
      </Paper>
    </div>
  );
};
