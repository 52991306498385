import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { registrationApi } from '@src/features/Registration/api/registrationApi';

import { Paper, TextInput, PasswordInput, Button, Title } from '@mantine/core';
import { FooterForm } from './components/FooterForm';

type InputChangeKeyType = 'email' | 'password' | 'confirmPassword';

import classes from './SignUpPage.module.css';

export const SignUpPage = () => {
  const navigate = useNavigate();
  const [userState, setUserState] = useState({
    email: '',
    password: '',
    confirmPassword: '',
  });

  const handleLogIn = async () => {
    const { email, password } = userState;
    if (!email.length && !password.length) return;

    const { status } = await registrationApi({
      email,
      password,
    });

    if (status === 201) navigate(-1);
  };

  const handleToBack = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ) => {
    event.preventDefault();
    navigate('/login');
  };

  const handleInputChange =
    (key: InputChangeKeyType) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setUserState((prev) => ({ ...prev, [key]: event.target.value }));
    };

  return (
    <div className={classes.wrapper}>
      <Paper className={classes.form} radius={0} p={30}>
        <Title order={2} className={classes.title} ta="center" mt="md" mb={50}>
          Registration to AHclaiming!
        </Title>

        <TextInput
          label="Email address"
          placeholder="hello@gmail.com"
          size="md"
          onChange={handleInputChange('email')}
        />
        <PasswordInput
          label="Password"
          placeholder="Your password"
          mt="md"
          size="md"
          onChange={handleInputChange('password')}
        />
        <PasswordInput
          label="Reenter password"
          placeholder="Reenter password"
          mt="md"
          size="md"
          onChange={handleInputChange('password')}
        />
        <Button fullWidth mt="xl" size="md" onClick={handleLogIn}>
          Registration
        </Button>

        <FooterForm onClick={handleToBack} />
      </Paper>
    </div>
  );
};
