import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { LoginPage } from '@src/pages/LoginPage';
import AppAuthProvider from '@src/app/providers/AppAuthProvider';

import { privateRoutes } from '@src/app/routes';
import { SignUpPage } from '@src/pages/SignUpPage';

const router = createBrowserRouter([
  {
    path: '/login',
    element: <LoginPage />,
  },
  {
    path: '/registration',
    element: <SignUpPage />,
  },
  ...privateRoutes.map(({ path, Page }) => {
    return {
      path,
      element: (
        <AppAuthProvider>
          <Page />
        </AppAuthProvider>
      ),
    };
  }),
]);

export const AppRouterProvider = () => {
  return <RouterProvider router={router} />;
};
