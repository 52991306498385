import { AppRouterProvider } from '@app/providers/AppRouterProvider';
import { AppStoreProvider } from '@app/providers/AppStoreProvider';
import { AppMantineProvider } from '@src/app/providers/AppMantineProvider';

import '@mantine/core/styles.css';
import '@app/styles/App.css';

export const App = () => {
  return (
    <AppMantineProvider>
      <AppStoreProvider>
        <AppRouterProvider />
      </AppStoreProvider>
    </AppMantineProvider>
  );
};

export default App;
