import axiosInstance from "@src/shared/axiosConfig";

interface ILoginCredentials{
    email: string;
    password: string;
}

export const registrationApi = async  (credentials:  ILoginCredentials) => {
    const { data, status } =  await  axiosInstance.post('/api/user/register', {
        email:  credentials.email,
        password: credentials.password
    })

    return  {data, status}
}